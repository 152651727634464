import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >


<path d="M5125 9354 c-897 -43 -1796 -309 -2475 -732 -746 -466 -1243 -1085
-1424 -1773 -50 -191 -67 -314 -73 -524 -4 -169 -2 -224 16 -355 44 -327 150
-634 318 -925 194 -334 504 -673 868 -945 123 -92 174 -127 340 -230 39 -24
72 -45 74 -47 3 -2 -22 -45 -41 -68 -75 -91 -77 -95 -80 -219 l-3 -116 1255 0
c1120 0 1258 -2 1278 -16 12 -8 22 -21 22 -29 0 -16 -52 -119 -62 -123 -5 -2
-8 -12 -8 -23 0 -10 -5 -19 -11 -19 -5 0 -8 -4 -4 -9 3 -5 1 -12 -4 -16 -6 -3
-8 -12 -4 -20 3 -8 0 -15 -6 -15 -6 0 -11 -7 -11 -15 0 -8 -4 -15 -10 -15 -5
0 -10 -5 -10 -11 0 -5 6 -7 13 -4 6 4 4 0 -5 -8 -10 -8 -18 -19 -18 -25 0 -6
-8 -16 -17 -21 -10 -6 -13 -11 -8 -11 6 0 3 -5 -5 -11 -12 -8 -12 -12 -2 -16
10 -4 9 -8 -4 -17 -16 -12 -16 -16 2 -62 19 -51 94 -205 103 -214 3 -3 22 -27
41 -55 184 -264 401 -356 605 -256 60 30 125 90 125 117 0 7 4 14 8 16 4 1 22
30 38 63 37 71 117 224 202 386 42 79 60 123 56 140 -4 20 -3 21 4 5 7 -16 15
-7 50 58 23 43 42 84 42 91 0 7 3 15 8 17 9 4 89 142 97 169 4 11 24 50 44 87
21 37 62 114 92 172 30 58 56 107 59 110 3 3 19 32 36 65 72 140 127 241 135
250 5 5 9 17 9 27 0 10 4 18 8 18 5 0 16 17 25 38 9 20 35 66 58 102 24 35 51
91 61 123 73 235 -76 675 -294 872 -50 45 -153 115 -221 150 -21 11 -33 21
-27 23 5 2 10 12 10 23 0 10 4 19 10 19 5 0 7 7 4 15 -4 8 -1 15 5 15 6 0 11
7 11 15 0 8 5 15 11 15 6 0 8 7 5 15 -3 9 1 18 9 21 8 4 12 10 9 15 -3 5 1 12
9 15 9 3 13 13 10 25 -3 11 0 19 6 19 7 0 9 5 6 10 -3 6 0 13 9 16 8 3 12 12
9 20 -3 8 1 14 11 14 10 0 13 6 10 15 -4 9 0 15 10 15 21 0 20 6 -9 78 -76
186 -244 406 -374 487 -109 68 -242 92 -346 64 -53 -15 -115 -80 -139 -147
-10 -29 -20 -51 -22 -49 -2 2 16 100 40 218 57 276 191 936 225 1107 15 73 25
135 22 137 -2 3 -181 5 -398 5 -366 0 -394 -1 -400 -17 -3 -10 -20 -49 -38
-88 -29 -63 -44 -110 -50 -155 -1 -8 -5 -22 -8 -30 -3 -8 -8 -28 -10 -45 -3
-16 -10 -58 -16 -93 -7 -35 -10 -73 -7 -85 3 -12 3 -15 -1 -7 -6 15 -44 -136
-69 -280 -8 -47 -23 -125 -34 -175 -18 -76 -37 -172 -67 -333 -3 -18 -9 -31
-14 -30 -4 2 -32 26 -61 54 -173 164 -333 230 -593 241 -315 14 -591 -75 -825
-264 -385 -312 -624 -968 -556 -1528 32 -269 129 -498 273 -642 183 -182 398
-263 705 -263 241 0 432 57 629 186 36 24 68 44 70 44 2 0 4 -15 4 -32 1 -18
2 -59 3 -90 l2 -58 148 -1 c81 -1 139 -3 130 -6 -22 -6 -22 -8 1 -82 10 -34
15 -63 11 -66 -4 -2 -449 -4 -989 -2 -647 1 -982 -1 -984 -8 -6 -17 -218 131
-372 258 -33 28 -116 107 -185 176 -338 342 -532 713 -585 1119 -101 771 350
1516 1235 2040 375 222 850 403 1331 506 944 202 1960 154 2859 -136 243 -78
559 -214 755 -325 631 -357 1054 -818 1234 -1345 50 -146 79 -299 87 -463 21
-416 -127 -833 -434 -1219 -75 -94 -267 -287 -372 -373 -234 -192 -530 -373
-832 -508 -48 -21 -86 -40 -84 -42 12 -12 442 193 616 295 835 488 1380 1170
1550 1942 38 176 53 343 47 535 -7 210 -25 340 -71 520 -146 568 -503 1087
-1043 1518 -682 543 -1592 886 -2613 982 -160 15 -633 26 -795 19z m-58 -1986
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-68 -335 c-11 -57 -23
-73 -14 -18 8 47 13 65 18 65 3 0 0 -21 -4 -47z m-57 -250 c-3 -16 -7 -22 -10
-15 -4 13 4 42 11 42 2 0 2 -12 -1 -27z m-23 -150 c-11 -57 -23 -73 -14 -18 8
47 13 65 18 65 3 0 0 -21 -4 -47z m-22 -110 c-3 -10 -5 -4 -5 12 0 17 2 24 5
18 2 -7 2 -21 0 -30z m-40 -175 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m780 -200 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m489
-23 c10 -8 15 -14 9 -14 -5 0 -19 6 -29 14 -11 8 -15 15 -10 15 6 0 19 -7 30
-15z m-212 -22 c-4 -10 -11 -49 -14 -88 l-7 -70 -1 62 c-2 54 12 113 26 113 2
0 1 -8 -4 -17z m202 -10 c-6 -6 -36 9 -36 18 0 5 9 4 20 -3 10 -7 18 -14 16
-15z m-1691 6 c22 -5 45 -11 50 -12 6 -1 28 -13 48 -25 21 -12 40 -22 43 -22
9 0 93 -94 94 -104 0 -5 6 -17 14 -25 28 -33 46 -111 36 -155 -5 -22 -11 -54
-14 -71 -8 -43 -8 -42 -146 -715 -17 -81 -28 -111 -48 -134 -53 -56 -170 -134
-243 -162 -67 -25 -86 -28 -214 -28 -131 -1 -144 1 -195 25 -164 78 -229 255
-210 565 5 85 4 121 -4 126 -8 6 -8 8 0 8 7 0 19 35 29 78 41 187 113 362 178
436 9 11 17 24 17 28 0 14 73 80 125 113 50 31 147 71 195 78 52 9 206 6 245
-4z m1746 -6 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m49
-49 c0 -3 -7 -1 -15 6 -8 7 -15 17 -15 22 0 5 7 3 15 -6 8 -8 15 -18 15 -22z
m-644 -21 c-18 -33 -21 -26 -6 13 6 15 13 24 16 21 3 -3 -1 -18 -10 -34z m71
0 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m550 12 c8 -9 13 -18
11 -21 -3 -2 -13 5 -23 16 -21 23 -11 27 12 5z m78 -52 c14 -21 25 -42 25 -48
0 -9 -9 3 -45 58 -26 41 -9 32 20 -10z m-638 -35 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m697 -64 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0
14 7 11 14 -7z m-863 -71 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13
-6 -23z m924 -16 c18 -4 18 -5 -2 -6 -14 -1 -26 7 -33 22 -10 19 -9 20 2 6 8
-9 22 -19 33 -22z m-435 -10 c0 -5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0 15 2 15
10 2 5 -8 10 -19 10 -23z m30 12 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5
7 10 4 6 -3 10 -11 10 -16z m-498 -11 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19
2 -3 -1 -11 -7 -17z m986 5 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1
23 -4z m-2727 -36 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z
m2242 -4 c-4 -10 -9 -11 -19 -2 -18 15 -18 15 5 15 11 0 17 -5 14 -13z m-1256
-15 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1338 11 c11 -16 1
-19 -13 -3 -7 8 -8 14 -3 14 5 0 13 -5 16 -11z m-45 -56 c0 -10 -4 -14 -8 -8
-4 6 -11 13 -16 18 -5 4 -2 7 7 7 10 0 17 -8 17 -17z m-2319 -20 c-12 -20 -14
-14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m1722 2 c-3 -9 -8 -14 -10 -11
-3 3 -2 9 2 15 9 16 15 13 8 -4z m1002 -5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11
10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-1044 -27 c-12 -20 -14 -14 -5 12 4 9 9
14 11 11 3 -2 0 -13 -6 -23z m690 10 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18
17 0 8 8 3 21 -13z m73 -12 c-2 -3 -12 3 -22 13 -16 17 -16 18 5 5 12 -7 20
-15 17 -18z m-363 -3 c11 -21 11 -22 -4 -9 -10 7 -17 17 -17 22 0 15 9 10 21
-13z m639 -3 c0 -12 -19 -12 -23 0 -4 9 -1 12 9 8 7 -3 14 -7 14 -8z m-300 -5
c13 -8 13 -10 -2 -10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z m-1429 -41 c-5 -13
-10 -19 -10 -12 -1 15 10 45 15 40 3 -2 0 -15 -5 -28z m1124 -9 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-2157 -75 c-3 -3
-9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m1665 10 c-3 -9 -8 -14 -10
-11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m567 -36 c0 -6 -4 -7 -10 -4 -5 3 -10 11
-10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-588 -11 c-7 -7 -12 -8 -12 -2 0 14
12 26 19 19 2 -3 -1 -11 -7 -17z m-648 -83 l-14 -50 6 45 c4 25 6 54 6 65 -1
16 1 17 8 5 5 -9 2 -35 -6 -65z m1392 -22 c-6 -6 -36 9 -36 18 0 5 9 4 20 -3
10 -7 18 -14 16 -15z m214 -116 c-14 -8 -27 -12 -30 -10 -7 6 23 23 40 23 8 0
4 -6 -10 -13z m-1070 -10 c0 -7 -7 -22 -15 -33 -13 -17 -13 -16 -3 14 13 34
18 40 18 19z m1135 -5 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z
m-1708 -24 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1847 -37
c-2 -3 -12 3 -22 13 -16 17 -16 18 5 5 12 -7 20 -15 17 -18z m-1857 -13 c-3
-7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1813 12 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m106 -38 c-4 -3 -14
2 -24 12 -16 18 -16 18 6 6 13 -6 21 -14 18 -18z m-2919 -64 c-2 -18 -4 -6 -4
27 0 33 2 48 4 33 2 -15 2 -42 0 -60z m990 40 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z m2004 -50 c15 -28 5 -21 -21 15 -15 20 -17 27 -6 18 9
-8 22 -23 27 -33z m-2014 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-5 -45 c-3 -16 -7 -22 -10 -15 -4 13 4 42 11 42 2 0 2 -12 -1 -27z
m-954 -78 c0 -16 -2 -15 -8 5 -4 14 -8 36 -8 50 0 22 1 22 8 -5 4 -16 8 -39 8
-50z m2439 49 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m113 -4
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m478
-15 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-2091 -27 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1632 -18 c0 -10 -3 -8 -9 5
-12 27 -12 43 0 25 5 -8 9 -22 9 -30z m15 -36 c3 -8 2 -12 -4 -9 -6 3 -10 10
-10 16 0 14 7 11 14 -7z m-211 -64 c3 -11 1 -18 -4 -14 -5 3 -9 12 -9 20 0 20
7 17 13 -6z m262 10 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m-2648 -42 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m1366 7 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m1045 -40 c-3
-3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-1608 -57 c0 -15 -15 -8
-28 12 -12 19 -11 20 8 8 11 -7 20 -16 20 -20z m50 7 c-7 -8 -18 -15 -24 -15
-6 0 -2 7 8 15 25 19 32 19 16 0z m1896 -26 c19 -22 19 -22 -3 -8 -13 8 -23
17 -23 21 0 12 6 9 26 -13z m329 -9 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3
6 11 10 16 10 6 0 7 -4 4 -10z m-2448 -26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m1874 -34 c5 0 9 -8 9 -17 0 -15 -2 -14 -15 3 -8 10 -14 26
-14 34 1 11 3 10 6 -2 2 -10 9 -18 14 -18z m554 20 c-12 -20 -43 -31 -51 -18
-4 7 -4 10 1 6 4 -4 17 -1 29 7 24 18 29 19 21 5z m-115 -10 c8 -5 11 -10 5
-10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m-2807 -17 c-4
-10 -9 -11 -19 -2 -18 15 -18 15 5 15 11 0 17 -5 14 -13z m2772 -5 c13 -5 9
-6 -15 -2 -19 4 -42 10 -50 15 -18 11 34 1 65 -13z m82 -4 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2700 -40 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m0 -20 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m196 -1 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-90
-10 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m2177 -3 c0 -5
-2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-1369 -67
c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m1447 -23 c7 -11
11 -20 9 -20 -2 0 -12 9 -22 20 -10 11 -14 20 -9 20 5 0 15 -9 22 -20z m97
-80 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-1585 -25 c4 3 5 -5 2 -17 -5 -19 -7 -20 -10 -5 -2 9 -8 17 -13 17 -6 0 -7 5
-3 12 6 9 9 9 12 0 2 -7 7 -10 12 -7z m1677 -31 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m80 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m223 -4 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0
9 -4 9 -10z m-270 -4 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z
m217 -2 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1930 -86 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1951 -39 c-3 -8 -7 -3 -11
10 -4 17 -3 21 5 13 5 -5 8 -16 6 -23z m-1991 -21 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m3 -68 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m37 -22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m8 -108 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m1806 -27 c-8 -15 -10 -15 -11 -2 0 17 10 32 18 25 2 -3 -1 -13
-7 -23z m-3811 -83 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z m2128 -185 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z
m27 -35 c10 -11 16 -20 13 -20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0 13
-9 23 -20z m1485 -24 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11
6 0 10 -2 10 -4z m-1440 -16 c0 -6 11 -15 24 -22 14 -6 27 -17 30 -25 4 -8
-12 0 -34 17 -42 33 -47 40 -30 40 6 0 10 -5 10 -10z m114 -45 c11 -8 16 -15
10 -15 -5 0 -18 7 -28 15 -11 8 -16 15 -10 15 5 0 18 -7 28 -15z m-8 -30 c10
-8 14 -15 8 -15 -5 0 -19 7 -30 15 -10 8 -14 15 -8 15 5 0 19 -7 30 -15z m71
-31 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1103 -183 c0 -6
-4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-1090 -43
c0 -5 -7 -3 -15 6 -8 8 -15 18 -15 22 0 3 7 1 15 -6 8 -7 15 -17 15 -22z m850
-412 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-840 -106 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m50 -65 c7 -8 9 -15 6 -15 -4 0 -14 7 -22 15 -9 8 -11 15 -6 15 5 0
15 -7 22 -15z m560 -179 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z"/>
<path d="M7390 3530 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M7340 3510 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M2568 2550 c-11 -7 -21 -40 -33 -107 -23 -133 -18 -125 -59 -108 -82
34 -177 12 -243 -57 -57 -59 -87 -140 -91 -249 -4 -87 -2 -100 23 -152 42 -89
114 -125 212 -105 26 5 53 9 58 8 6 0 36 -3 67 -6 l57 -6 10 39 c5 21 35 184
66 363 30 179 58 332 61 341 8 27 -23 49 -69 49 -23 0 -49 -5 -59 -10z m-97
-359 c15 -18 15 -29 -3 -131 -10 -62 -24 -118 -31 -125 -42 -41 -108 -47 -132
-11 -50 75 -11 244 65 284 32 16 80 8 101 -17z"/>
<path d="M6008 2548 c-16 -12 -30 -74 -58 -243 -6 -38 -22 -128 -35 -200 -12
-71 -28 -164 -35 -205 -7 -41 -15 -80 -17 -86 -14 -38 98 -62 131 -29 14 14
19 14 47 -1 46 -23 136 -21 186 5 113 57 185 242 154 393 -14 67 -43 114 -91
146 -30 21 -44 23 -107 20 -62 -3 -73 -1 -73 12 0 18 18 126 26 151 3 9 -3 24
-11 33 -20 19 -94 22 -117 4z m211 -354 c25 -31 29 -121 9 -187 -18 -59 -67
-107 -110 -107 -38 0 -78 30 -78 59 0 30 30 184 40 209 11 24 60 51 97 52 12
0 31 -12 42 -26z"/>
<path d="M2803 2520 c-46 -19 -59 -99 -21 -131 27 -24 90 -25 110 -1 38 43 29
109 -18 131 -29 13 -41 13 -71 1z"/>
<path d="M4045 2505 c-33 -32 -34 -90 -3 -116 43 -38 119 -16 132 37 20 79
-73 136 -129 79z"/>
<path d="M4284 2459 c-4 -6 -12 -35 -18 -63 -11 -51 -12 -51 -53 -54 -44 -3
-45 -5 -58 -68 -7 -37 14 -64 51 -64 30 0 30 -4 4 -151 -24 -135 -25 -178 -6
-223 20 -48 51 -66 114 -66 79 0 92 10 92 74 0 57 -10 69 -48 59 -11 -2 -22
-1 -25 4 -3 5 5 71 19 148 13 77 24 143 24 146 0 4 17 9 38 11 20 3 43 7 51 9
11 3 22 -40 51 -198 l36 -201 -23 -46 c-26 -52 -47 -66 -97 -66 -42 0 -56 -20
-56 -77 0 -51 14 -63 72 -63 65 0 107 19 149 68 46 55 354 666 346 686 -5 12
-22 16 -75 16 l-68 0 -34 -72 c-112 -241 -103 -226 -111 -193 -5 17 -16 82
-26 145 l-18 115 -104 3 -103 3 6 50 c5 33 3 56 -5 65 -14 17 -115 19 -125 3z"/>
<path d="M3052 2325 c-56 -32 -76 -56 -115 -135 -27 -56 -32 -78 -35 -163 -4
-89 -2 -101 22 -152 43 -86 113 -122 206 -105 47 9 50 0 13 -35 -41 -37 -107
-35 -153 5 -19 16 -42 29 -51 30 -17 0 -79 -60 -79 -78 0 -19 68 -81 108 -98
50 -22 148 -22 205 0 53 20 125 94 144 147 17 48 105 570 99 586 -8 19 -125
18 -132 -1 -5 -12 -11 -11 -37 5 -48 29 -139 26 -195 -6z m173 -129 c13 -13
22 -32 20 -42 -2 -11 -9 -50 -15 -89 -17 -104 -27 -129 -59 -148 -56 -33 -103
-18 -121 39 -21 61 5 179 48 227 37 42 92 48 127 13z"/>
<path d="M3655 2341 c-10 -4 -52 -6 -91 -3 -64 4 -72 2 -78 -14 -3 -11 -23
-125 -46 -254 -22 -129 -42 -243 -44 -253 -2 -9 2 -24 11 -32 18 -19 106 -20
121 -2 6 7 25 94 42 193 27 156 35 183 57 206 30 32 74 44 102 28 28 -14 27
-58 -4 -235 -37 -206 -37 -205 48 -205 72 0 65 -19 117 322 20 130 -4 204 -81
243 -29 16 -120 19 -154 6z"/>
<path d="M5064 2332 c-106 -44 -154 -132 -119 -220 20 -48 69 -83 158 -111 75
-24 90 -34 83 -58 -13 -39 -92 -56 -132 -28 -12 8 -24 28 -27 45 -6 30 -7 30
-71 30 -75 0 -84 -11 -66 -80 26 -96 110 -146 232 -138 99 6 157 38 192 105
21 41 21 117 0 153 -21 34 -86 77 -139 91 -98 25 -120 50 -73 83 46 32 118 6
118 -43 0 -23 19 -31 70 -31 58 0 70 8 70 45 0 102 -84 175 -200 174 -30 0
-73 -8 -96 -17z"/>
<path d="M6830 2329 c-111 -50 -170 -159 -170 -316 0 -114 29 -173 110 -222
48 -29 153 -29 215 0 58 27 111 87 125 140 10 38 10 45 -6 56 -39 29 -134 9
-134 -28 0 -24 -54 -59 -92 -59 -33 0 -41 5 -58 33 -52 85 1 262 86 283 46 12
77 -6 94 -56 l16 -45 61 -3 c72 -3 78 4 62 79 -28 131 -178 198 -309 138z"/>
<path d="M7324 2331 c-100 -45 -163 -151 -172 -284 -8 -128 31 -213 119 -257
51 -26 158 -27 214 -1 56 25 118 97 145 167 27 69 32 187 11 247 -18 52 -67
106 -116 128 -53 25 -147 24 -201 0z m161 -143 c25 -30 27 -37 23 -103 -5 -70
-30 -138 -61 -164 -24 -19 -85 -25 -112 -11 -74 39 -51 230 34 287 48 33 83
30 116 -9z"/>
<path d="M7912 2341 c-7 -4 -46 -6 -86 -3 -61 4 -74 2 -79 -12 -4 -9 -25 -123
-47 -253 -23 -131 -43 -245 -46 -254 -8 -29 23 -49 75 -49 27 0 53 6 59 13 5
6 24 95 42 197 30 172 34 187 61 213 53 49 119 29 119 -36 0 -18 -11 -97 -25
-177 -29 -171 -30 -196 -9 -204 22 -8 97 -7 111 2 6 4 23 77 38 162 14 85 31
171 37 190 19 63 71 100 119 85 40 -13 41 -36 8 -230 -25 -148 -28 -189 -19
-200 14 -17 96 -20 119 -6 17 11 70 311 71 397 0 70 -22 113 -80 152 -49 33
-156 28 -215 -9 l-40 -26 -39 29 c-33 24 -49 28 -100 28 -34 0 -67 -4 -74 -9z"/>
<path d="M2746 2332 c-2 -4 -18 -89 -36 -187 -17 -99 -38 -218 -47 -264 -15
-80 -15 -85 3 -98 23 -16 95 -17 116 0 11 8 28 82 57 253 23 133 41 256 41
273 l0 31 -64 0 c-36 0 -67 -4 -70 -8z"/>
<path d="M3992 2273 c-7 -38 -28 -160 -48 -273 -34 -194 -35 -205 -18 -217 23
-17 108 -17 121 0 11 13 83 409 90 500 l5 57 -69 0 -70 0 -11 -67z"/>
<path d="M5445 2328 c-5 -15 -31 -167 -50 -292 -29 -186 27 -271 174 -266 36
1 100 2 143 3 42 1 79 4 81 7 9 9 98 533 93 546 -4 10 -25 14 -70 14 -60 0
-66 -2 -71 -22 -3 -13 -18 -98 -34 -189 -16 -92 -35 -176 -42 -187 -27 -43
-111 -55 -133 -20 -8 13 -3 65 20 205 17 103 29 194 27 201 -4 8 -27 12 -69
12 -43 0 -66 -4 -69 -12z"/>
<path d="M6427 1918 c-40 -31 -40 -103 1 -134 28 -22 80 -17 107 11 51 50 17
135 -54 135 -20 0 -45 -6 -54 -12z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
